import './app.css'
import 'flickity/css/flickity.css';
import Flickity from 'flickity';

// Top navigation slider
////////////////////////

const navigation = document.getElementById('Navigation');
let previousButton = navigation.querySelector('#LeftButton');
let nextButton = navigation.querySelector('#RightButton');
let cycleIntervalId = null;

const cycleServices = function() {
    cycleIntervalId = setInterval(function() {
        cycleNext();
    }, 1500);
};

const cyclePrevious= function() {
    const activeLink = navigation.querySelector('.active');

    activeLink.classList.toggle('hidden');
    activeLink.classList.toggle('active');

    if(activeLink.previousElementSibling) {
        activeLink.previousElementSibling.classList.toggle('hidden');
        activeLink.previousElementSibling.classList.toggle('active');
    }
    else {
        var links = navigation.querySelectorAll('a');
        links[links.length - 1].classList.toggle('hidden');
        links[links.length - 1].classList.toggle('active');
    }
}

const cycleNext = function() {
    const activeLink = navigation.querySelector('.active');

    activeLink.classList.toggle('hidden');
    activeLink.classList.toggle('active');

    if(activeLink.nextElementSibling) {
        activeLink.nextElementSibling.classList.toggle('hidden');
        activeLink.nextElementSibling.classList.toggle('active');
    }
    else {
        var links = navigation.querySelectorAll('a');
        links[0].classList.toggle('hidden');
        links[0].classList.toggle('active');
    }
}

previousButton.addEventListener('click', cyclePrevious);
nextButton.addEventListener('click', cycleNext);

navigation.addEventListener('mouseenter', function() {
   clearInterval(cycleIntervalId);
});
navigation.addEventListener('mouseleave', function() {
    cycleServices();
});

cycleServices();

// FAQ Block
///////////////////

var faqMatrixs = document.querySelectorAll('section[data-section="faqs"]');

faqMatrixs.forEach(function (faqMatrix) {
    var questions = faqMatrix.querySelectorAll('div[data-faq="question"]');

    questions.forEach(function(question) {
        var content = question.querySelector('[data-faq="content"]');
        var plusButton = question.querySelector('[data-faq="plusButton"]');
        var minusButton = question.querySelector('[data-faq="minusButton"]');
    
        question.addEventListener('click', function() {
            content.classList.toggle('hidden');
            plusButton.classList.toggle('hidden');
            minusButton.classList.toggle('hidden');
        });
    });
});

// Homepage Carousel Block
//////////////////////////

var carouselBlocks = document.querySelectorAll('section[data-section="carousel"]');

carouselBlocks.forEach(function (carouselBlock) {
    carouselBlock.addEventListener('mouseenter', function() {
        // TODO: Stop the scrolling animation
    });
    carouselBlock.addEventListener('mouseleave', function() {
        // TODO: Start the scrolling animation
    });
});

// Homepage Slider Block
////////////////////////

var sliderBlocks = document.querySelectorAll('section[data-section="slider"]');

sliderBlocks.forEach(function (sliderBlock) {

    var mainCarousel = sliderBlock.querySelector('.main-carousel');

    // Initiate Flickity instance for all slider matrixs onn homepage.
    var flkty = new Flickity(mainCarousel, {
        prevNextButtons: false,
        autoPlay: true
    });

    // Set active slider heading on slide change
    flkty.on('change', function(index) {
        var slider = this.element.closest('section[data-section="slider"]');
        
        var activeHeading = slider.querySelector('.carousel-cell-active');
        activeHeading.classList.toggle('carousel-cell-active');

        var nextHeading = slider.querySelectorAll('div[data-slider="heading"]')[index];
        nextHeading.classList.toggle('carousel-cell-active');

        var activeImage = slider.querySelector('.carousel-image-active');
        activeImage.classList.toggle('carousel-image-active');

        var nextImage = slider.querySelectorAll('div[data-slider="image"]')[index];
        nextImage.classList.toggle('carousel-image-active');
    });

    // Add mouse click events to slider headings
    var sliderHeadings = sliderBlock.querySelectorAll('div[data-slider="heading"]');
    sliderHeadings.forEach(function(sliderHeading, index) {
        sliderHeading.addEventListener('click', function() {
            flkty.select(index);
        });
    });
});